import { TypeFilterValueKey } from '../../filters';
import { LoomIcon } from '../../icons/loom';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const loomConfig: ThirdPartyConfig = {
	displayName: 'Loom',
	id: 'loom',
	icon: LoomIcon,
	integrationAri: 'ari:cloud:platform::integration/loom',
	typeFilterValues: [TypeFilterValueKey.Video],
	filters: ['lastUpdated'],
	requiresOauth: false,
	isEnabled: () => true,
};
