import { type EntityATI } from '@atlassian/dynamic-cards-search-client';

import {
	DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_VIDEO_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
} from './entities';
import { type ProductKeys } from './products';

export const CUSTOM_OPTION_VALUE = 'custom';
export const universalTypeFilterId = 'universalTypeFilter';

// TODO QS-4805: We're slowly moving all filter typing to use this as a reference so we can be strongly typed!
export type FilterId =
	| 'assignee'
	| 'content'
	| 'contributor'
	| 'lastUpdated'
	| 'project'
	| 'space'
	| 'verified'
	| 'onlyTitleMatch'
	| 'universalTypeFilter'
	| 'owner'
	| 'label';

// Keeping these seperate for now
export type FilterControlDropdown = 'dropdown';
export type FitlerControlSort = 'sort';
export type FilterControlId = FilterControlDropdown | FitlerControlSort;
export type FilterOrControlId = FilterId | FilterControlId;

// TOOD: update the filter ordering to match design
export const filterIdOrdering: string[] = [
	'lastUpdated',
	universalTypeFilterId,
	'contributor',
	'owner',
	'space',
	'assignee',
	'project',
	'content',
	'onlyTitleMatch',
	'verified',
	'label',
];

export const getFilterIdOrdering = (): string[] => {
	return filterIdOrdering.filter(() => {
		return true;
	});
};

// Universal type filter
// TODO QS-5466 maybe move to constants/type-filter

/**
 * The set of possible values the type filter shows a user
 *
 * This is the superset. Only values that have results for a 1P/3P available to an end user will be shown
 */
export const TypeFilterValueKey = {
	Document: 'document',
	Spreadsheet: 'spreadsheet',
	Presentation: 'presentation',
	Repository: 'repository',
	PullRequest: 'pull-request',
	Branch: 'branch',
	Commit: 'commit',
	WebPage: 'web-page',
	Conversation: 'conversation',
	Message: 'message',
	Video: 'video',
	Design: 'design',
	Page: 'page',
	Blogpost: 'blogpost',
	Attachment: 'attachment',
	Space: 'space',
	Whiteboard: 'whiteboard',
	Comment: 'comment',
	Database: 'database',
	Embed: 'embed',
	Folder: 'folder',
	Calendar: 'calendar',
	SpaceCalendar: 'space-calendar',
	Question: 'question',
	Answer: 'answer',
	Issue: 'issue',
	Component: 'component',
	Board: 'board',
	Card: 'card',
	Project: 'project',
	Goal: 'goal',
	Tag: 'tag',
	WorkItem: 'work-item',
} as const;
export type TypeFilterValueKey = (typeof TypeFilterValueKey)[keyof typeof TypeFilterValueKey];
export const allTypeFilterValueKeys = Object.values(TypeFilterValueKey);

export const NounSubtypeKey = {
	Document: 'document',
	Spreadsheet: 'spreadsheet',
	Presentation: 'presentation',
	File: 'file',
	Database: 'database',
	WebPage: 'web-page',
} as const;
export type NounSubtypeKey = (typeof NounSubtypeKey)[keyof typeof NounSubtypeKey];

export interface TypeFilterMapping {
	products: ProductKeys[];
	entities: Partial<Record<ProductKeys, EntityATI[]>>;
	subCategories: NounSubtypeKey[];
}

export type UniversalTypeFilterMapping = Record<TypeFilterValueKey, TypeFilterMapping>;

/**
 * A mapping between UI values for the type filter
 * - TypeFilterKey e.g. 'Spreadsheet'
 *
 * and TWG entities to request from Aggregator
 * - entities3P e.g. ['ati:cloud:graph:document'],
 * - subTypes3P e.g. ['spreadsheet']
 */
export const TypeFilterTWGNounMapping = new Map<
	TypeFilterValueKey,
	{ entities3P: EntityATI[]; subTypes3P: NounSubtypeKey[] }
>([
	[
		TypeFilterValueKey.Document,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			subTypes3P: [NounSubtypeKey.Document],
		},
	],
	[
		TypeFilterValueKey.Spreadsheet,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			subTypes3P: [NounSubtypeKey.Spreadsheet],
		},
	],
	[
		TypeFilterValueKey.Presentation,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			subTypes3P: [NounSubtypeKey.Presentation],
		},
	],
	[
		TypeFilterValueKey.Repository,
		{
			entities3P: ['ati:cloud:graph:repository'],
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.PullRequest,
		{
			entities3P: ['ati:cloud:graph:pull-request'],
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.Branch,
		{
			entities3P: ['ati:cloud:graph:branch'],
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.Commit,
		{
			entities3P: ['ati:cloud:graph:commit'],
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.WebPage,
		{
			entities3P: ['ati:cloud:graph:document'],
			subTypes3P: [NounSubtypeKey.WebPage],
		},
	],
	[
		TypeFilterValueKey.Conversation,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI,
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.Message,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI,
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.Video,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_VIDEO_ENTITY_ATI,
			subTypes3P: [],
		},
	],
	[
		TypeFilterValueKey.Design,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
			subTypes3P: [NounSubtypeKey.File],
		},
	],
	[
		TypeFilterValueKey.WorkItem,
		{
			entities3P: DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
			subTypes3P: [],
		},
	],
	// TODO: >>>> QS-5465: Uncomment when calendar nouns are ready to be ingested
	// [TypeFilterValueKey.Calendar]: {
	// 	entities3P: DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI,
	// 	subTypes3P: [],
	// }
]);
