import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import { TypeFilterValueKey } from '../../filters';
import { WebDomainIcon } from '../../icons/web-domain';
import { WebDomainSearchResultIcon } from '../../icons/web-domain-search';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { messages } from './messages';

export const atlassianWebCrawlerConfig: ThirdPartyConfig = {
	displayName: <FormattedMessage {...messages['atlassian-web-crawler']} />,
	id: 'atlassian-web-crawler',
	resourceType: 'webcrawler-connector',
	icon: WebDomainIcon,
	searchResultIcon: WebDomainSearchResultIcon,
	integrationAri: 'ari:cloud:platform::integration/atlassian-web-crawler',
	typeFilterValues: [TypeFilterValueKey.WebPage],
	requiresOauth: false,
	isEnabled: () => true,
};
