import { TypeFilterValueKey } from '../../filters';
import { DropboxIcon } from '../../icons/dropbox';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const dropboxConfig: ThirdPartyConfig = {
	displayName: 'Dropbox',
	id: 'dropbox',
	resourceType: 'dropbox-connector',
	icon: DropboxIcon,
	integrationAri: 'ari:cloud:platform::integration/dropbox',
	typeFilterValues: [
		TypeFilterValueKey.Document,
		TypeFilterValueKey.Spreadsheet,
		TypeFilterValueKey.Presentation,
	],
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	// fg('enable_3p_search_dropbox') => false
	isEnabled: () => false,
};
