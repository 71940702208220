import { GmailIcon } from '../../icons/gmail';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const gmailConfig: ThirdPartyConfig = {
	displayName: 'Gmail',
	id: 'gmail',
	resourceType: 'gmail-connector',
	icon: GmailIcon,
	integrationAri: 'ari:cloud:platform::integration/gmail',
	requiresOauth: true,
	typeFilterValues: [],
	// fg('enable_3p_search_gmail') => false
	isEnabled: () => false,
};
