import { TypeFilterValueKey } from '../../filters';
import AsanaIcon from '../../icons/asana';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const asanaConfig: ThirdPartyConfig = {
	displayName: 'Asana',
	id: 'asana',
	icon: AsanaIcon,
	integrationAri: 'ari:cloud:platform::integration/asana', // this hasn't been implemented in the codebase yet, so will need to confirm that it is correct in the future,
	typeFilterValues: [TypeFilterValueKey.WorkItem],
	requiresOauth: true,
	isEnabled: () => false,
};
