import { TypeFilterValueKey } from '../../filters';
import { MiroIcon } from '../../icons/miro';
import { MiroFilterIcon } from '../../icons/miro-filter';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const miroConfig: ThirdPartyConfig = {
	displayName: 'Miro',
	id: 'miro',
	resourceType: 'miro-connector',
	icon: MiroIcon,
	searchResultIcon: MiroFilterIcon,
	integrationAri: 'ari:cloud:platform::integration/miro',
	typeFilterValues: [TypeFilterValueKey.Design],
	filters: ['lastUpdated'],
	requiresOauth: false,
	isEnabled: () => true,
};
