import { type AggRequest, type AggResponse, fetchAgg } from '../../../common/utils/fetch-agg';
import { generateHashFromVariables } from '../../../utils/caching/common/key-generator';
import { inMemoryCache } from '../../../utils/caching/in-memory';

import {
	operationName,
	queryGenerator,
	resultsCountOperationName,
	resultsCountQueryGenerator,
	type ResultsCountQueryVariables,
	type SearchPageQueryVariables,
} from './query-generator';
import { type SearchPageData } from './types';

export type SearchPageQueryProps = Pick<
	AggRequest<SearchPageQueryVariables>,
	'aggAbsoluteUrl' | 'variables'
> & {
	originalQuery?: string;
};

export type ResultsCountQueryProps = Pick<
	AggRequest<ResultsCountQueryVariables>,
	'aggAbsoluteUrl' | 'variables'
> & {
	originalQuery?: string;
};

export const cache = inMemoryCache<AggResponse<SearchPageData>>();

export const generateCacheKey = (
	variables: SearchPageQueryVariables | ResultsCountQueryVariables,
	originalQuery?: string,
) => {
	/**
	 * Results once fetched will be used for upto 15 minutes from an in memory cache.
	 * 1. Generate key based on query, entities, filters
	 * 2. Serve from cache if present without resetting the expiration, evict if results are expired
	 * 3. If not present or expired then invoke the api
	 * 4. If API is invoked then store response in the cache with expiration and return back the response
	 */
	const keyObj = {
		query: variables.query,
		entities: variables.entities,
		commonFilters: variables.commonFilters,
		confluenceFilters: variables.confluenceFilters,
		jiraFilters: variables.jiraFilters,
		thirdPartyFilters: variables.thirdPartyFilters,
		cloudIdARI: variables.cloudIdARI,
		sortField: variables.sort.at(0)?.field ?? '',
		sortOrder: variables.sort.at(0)?.order ?? '',
		after: variables.after,
		originalQuery,
	};

	return generateHashFromVariables(keyObj);
};

// Expire the cache if there are errors or edges are empty
const expireWhen = (result: AggResponse<SearchPageData>) => {
	const { data, errors } = result;
	if (!data || (errors && errors.length > 0)) {
		return true;
	}
	return data.search.results.edges.length === 0;
};

export const searchPageQuery = ({
	variables,
	aggAbsoluteUrl,
	originalQuery,
}: SearchPageQueryProps) => {
	const graphQuery = queryGenerator();

	return cache.inMemoryDecorator(
		generateCacheKey(variables, originalQuery),
		() =>
			fetchAgg<SearchPageQueryVariables, SearchPageData>({
				variables,
				aggAbsoluteUrl,
				graphQuery,
				operationName,
			}),
		expireWhen,
	);
};

export const resultsCountQuery = ({
	variables,
	aggAbsoluteUrl,
	originalQuery,
}: ResultsCountQueryProps) => {
	const graphQuery = resultsCountQueryGenerator();

	return cache.inMemoryDecorator(generateCacheKey(variables, originalQuery), () =>
		fetchAgg<ResultsCountQueryVariables, SearchPageData>({
			variables,
			aggAbsoluteUrl,
			graphQuery,
			operationName: resultsCountOperationName,
		}),
	);
};
