import { TypeFilterValueKey } from '../../filters';
import { SlackIcon } from '../../icons/slack';
import { SlackSearchResultIcon } from '../../icons/slack-search';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const slackConfig: ThirdPartyConfig = {
	displayName: 'Slack',
	id: 'slack',
	resourceType: 'ai-3p-connector:slack-workspace',
	icon: SlackIcon,
	searchResultIcon: SlackSearchResultIcon,
	integrationAri: 'ari:cloud:platform::integration/slack',
	typeFilterValues: [TypeFilterValueKey.Conversation, TypeFilterValueKey.Message],
	filters: ['lastUpdated'],
	requiresOauth: true,
	isEnabled: () => true,
};
