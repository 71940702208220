import GoogleCalendarIcon from '../../icons/google-calendar';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const googleCalendarConfig: ThirdPartyConfig = {
	displayName: 'Google Calendar',
	id: 'google-calendar',
	resourceType: 'google-calendar-connector',
	icon: GoogleCalendarIcon,
	integrationAri: 'ari:cloud:platform::integration/google-calendar',
	requiresOauth: true,
	// TODO: >>>> QS-5465: Uncomment when calendar nouns are ready to be ingested
	typeFilterValues: [],
	// typeFilterValues: [TypeFilterValueKey.Calendar],
	// fg('enable_3p_search_google_calendar') => false
	isEnabled: () => false,
};
