import React from 'react';

export const WebDomainIcon = () => (
	<svg width="32" height="32" viewBox="3 3 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.39008 15.0002H11.02C11.117 12.621 11.5575 10.4492 12.2472 8.78245C12.3552 8.52159 12.4707 8.26946 12.594 8.02828C9.793 9.22645 7.75112 11.8577 7.39008 15.0002ZM15.9997 5.3335C10.1086 5.3335 5.33301 10.1091 5.33301 16.0002C5.33301 21.8912 10.1086 26.6668 15.9997 26.6668C21.8907 26.6668 26.6663 21.8912 26.6663 16.0002C26.6663 10.1091 21.8907 5.3335 15.9997 5.3335ZM15.9997 7.3335C15.7983 7.3335 15.5142 7.42791 15.1575 7.79247C14.7976 8.16034 14.4274 8.74437 14.0953 9.54715C13.5201 10.9372 13.1178 12.84 13.0218 15.0002H18.9776C18.8816 12.84 18.4793 10.9372 17.9041 9.54715C17.5719 8.74437 17.2017 8.16034 16.8418 7.79247C16.4852 7.42791 16.2011 7.3335 15.9997 7.3335ZM20.9794 15.0002C20.8824 12.621 20.4418 10.4492 19.7521 8.78245C19.6442 8.52159 19.5286 8.26946 19.4054 8.02828C22.2063 9.22645 24.2482 11.8577 24.6093 15.0002H20.9794ZM18.9776 17.0002H13.0218C13.1178 19.1604 13.5201 21.0631 14.0953 22.4532C14.4274 23.2559 14.7976 23.84 15.1575 24.2079C15.5142 24.5724 15.7983 24.6668 15.9997 24.6668C16.2011 24.6668 16.4852 24.5724 16.8418 24.2079C17.2017 23.84 17.5719 23.2559 17.9041 22.4532C18.4793 21.0631 18.8816 19.1604 18.9776 17.0002ZM19.4054 23.972C19.5286 23.7309 19.6442 23.4787 19.7521 23.2179C20.4418 21.5511 20.8824 19.3793 20.9794 17.0002H24.6093C24.2482 20.1427 22.2063 22.7739 19.4054 23.972ZM12.594 23.972C12.4707 23.7309 12.3552 23.4787 12.2472 23.2179C11.5575 21.5511 11.117 19.3793 11.02 17.0002H7.39008C7.75112 20.1427 9.793 22.7739 12.594 23.972Z"
			fill="currentColor"
		/>
	</svg>
);
