import { TypeFilterValueKey } from '../../filters';
import { GithubIcon } from '../../icons/github';
import { GithubSearchResultIcon } from '../../icons/github-search';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const githubConfig: ThirdPartyConfig = {
	displayName: 'Github',
	id: 'github',
	resourceType: 'github-connector',
	icon: GithubIcon,
	searchResultIcon: GithubSearchResultIcon,
	integrationAri: 'ari:cloud:platform::integration/github',
	typeFilterValues: [
		TypeFilterValueKey.Repository,
		TypeFilterValueKey.PullRequest,
		TypeFilterValueKey.Branch,
		TypeFilterValueKey.Commit,
	],
	filters: ['lastUpdated'],
	requiresOauth: true,
	isEnabled: () => true,
};
