import { TypeFilterValueKey } from '../../filters';
import ZendeskIcon from '../../icons/zendesk';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const zendeskConfig: ThirdPartyConfig = {
	displayName: 'Zendesk',
	id: 'zendesk',
	icon: ZendeskIcon,
	integrationAri: 'ari:cloud:platform::integration/zendesk', // this hasn't been implemented in the codebase yet, so will need to confirm that it is correct in the future
	typeFilterValues: [TypeFilterValueKey.WorkItem],
	requiresOauth: true,
	isEnabled: () => false,
};
