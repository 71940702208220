import { TypeFilterValueKey } from '../../filters';
import { TeamsIcon } from '../../icons/teams';
import { TeamsSearchResultIcon } from '../../icons/teams-search';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const teamsConfig: ThirdPartyConfig = {
	displayName: 'Teams',
	id: 'teams',
	resourceType: 'microsoft-teams-connector',
	icon: TeamsIcon,
	searchResultIcon: TeamsSearchResultIcon,
	integrationAri: 'ari:cloud:platform::integration/microsoft',
	typeFilterValues: [TypeFilterValueKey.Conversation, TypeFilterValueKey.Message],
	filters: ['lastUpdated'],
	requiresOauth: true,
	isEnabled: () => true,
};
