import { TypeFilterValueKey } from '../../filters';
import { GoogleDriveIcon } from '../../icons/google-drive';
import { GoogleDriveSearchResultIcon } from '../../icons/google-drive-search';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

// static 3P config object for google drive
export const googleDriveConfig: ThirdPartyConfig = {
	displayName: 'Google Drive',
	id: 'google',
	resourceType: 'ai-3p-connector:google-workspace',
	icon: GoogleDriveIcon,
	searchResultIcon: GoogleDriveSearchResultIcon,
	integrationAri: 'ari:cloud:platform::integration/google',
	typeFilterValues: [
		TypeFilterValueKey.Document,
		TypeFilterValueKey.Spreadsheet,
		TypeFilterValueKey.Presentation,
	],
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => true,
};
