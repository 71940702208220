import { useCallback, useEffect, useMemo, useState } from 'react';
import uuid from 'uuid/v4';

import type { EditorActions } from '@atlaskit/editor-core';

import { fg } from '@confluence/feature-gating';

import type { Card, LinkCardsParameters } from '../../link-cards/linkCardsTypes';
import { ImagePosition, RenderVariant } from '../../link-cards/linkCardsTypes';
import { checkCards } from '../../link-cards/LinkCardsContent';
import { useCustomSitesExtensions } from '../useCustomSitesExtensions';
import { linkCardsExtensionType } from '../../link-cards/linkCardsExtensionType';
import { CAROUSEL_VERSION_1 } from '../../carousel/carouselVersion';
import { CARDS_VERSION_1, CARDS_VERSION_2 } from '../../link-cards/linkCardsVersion';

import type {
	ExtensionActions,
	UpdateField,
	UseExtensionParametersAndActionsProps,
	UseExtensionParametersAndActionsResponse,
} from './configPanelTypes';
import { CARD_FIELDS } from './configPanelTypes';
import type { CustomSitesExtensionTypes } from './ConfigPanelContent';

export const initialUnsplashImage: Partial<Card> = {
	imageSrc:
		'https://images.unsplash.com/photo-1579547621706-1a9c79d5c9f1?auto=format&fit=crop&q=80&w=1080',
	imageAltText: 'purple and pink light illustration',
};

export const createNewCardWithDefaultParameters = (): Card => ({
	cardId: uuid(),
	link: '',
	isLinkUserEdited: false,
	title: '',
	isTitleUserEdited: false,
	description: '',
	isDescriptionUserEdited: false,
	imagePosition: ImagePosition.MIDDLE,
	...initialUnsplashImage,
});

export const updateLinkAndConfluencePageId = (
	link: string,
	confluencePageId?: string,
	isLinkUserEdited?: boolean,
) => {
	return {
		[CARD_FIELDS.LINK]: link,
		[CARD_FIELDS.CONFLUENCE_PAGE_ID]: confluencePageId || '',
		[CARD_FIELDS.IS_LINK_USER_EDITED]: isLinkUserEdited || false,
	};
};

export const updateImageSrcAndImageAltText = (imageSrc: string, imageAltText: string) => {
	return {
		[CARD_FIELDS.IMAGE_SRC]: imageSrc,
		[CARD_FIELDS.IMAGE_ALT_TEXT]: imageAltText,
	};
};

const updateNodeParameters = (
	editorActions: EditorActions,
	updatedParameters: LinkCardsParameters,
) => {
	const selectedNodeJSON = editorActions.getSelectedNode()?.toJSON();

	const updatedNodeSelection = {
		...selectedNodeJSON,
		attrs: {
			...selectedNodeJSON?.attrs,
			parameters: { ...updatedParameters },
		},
	};

	editorActions.replaceSelection(updatedNodeSelection, true);
};

const fetchLocalIdMacroParameters = (
	editorActions: EditorActions,
	localId: string,
	extensionType: CustomSitesExtensionTypes,
): LinkCardsParameters => {
	const localIdNode = editorActions?.getNodeByLocalId?.(localId)?.toJSON();
	let params = localIdNode?.attrs?.parameters;

	if (params === undefined) {
		return undefined as any;
	}

	// Make a copy of the params object to avoid mutating the original object
	params = { ...params };

	// If the extension was created prior to removal of type field, remove it from object here
	// This update is/was not done in combination with a feature gate
	delete params['type'];

	params = updateParamsForManualCardsGate(extensionType, params);
	params = updateParamsForDynamicCardsGate(extensionType, params);

	return params;
};

// change the name to updateParamsToVersion1 after removing fg('company_hub_manual_cards_refinement')
const updateParamsForManualCardsGate = (
	extensionType: CustomSitesExtensionTypes,
	params: LinkCardsParameters,
): LinkCardsParameters => {
	// With this feature gate, we remove the alignment field and add version field
	if (fg('company_hub_manual_cards_refinement')) {
		delete params['alignment'];

		// Always set to version 1
		if (extensionType === linkCardsExtensionType) {
			params.version = CARDS_VERSION_1;
		} else {
			params.version = CAROUSEL_VERSION_1;
		}
	}
	return params;
};

// change the name to updateParamsToVersion2 after removing fg('confluence_company_hub_dynamic_cards')
const updateParamsForDynamicCardsGate = (
	extensionType: CustomSitesExtensionTypes,
	params: LinkCardsParameters,
): LinkCardsParameters => {
	// With this feature gate, we update version to 2 and add renderVariant field
	if (fg('confluence_company_hub_dynamic_cards')) {
		// Only set for cards extension
		if (extensionType === linkCardsExtensionType) {
			// Always set version to 2
			params.version = CARDS_VERSION_2;
			// Set render variant to manual only if it is not set
			params.renderVariant ??= RenderVariant.MANUAL;
		}
	}
	return params;
};

const removeProperties = (
	extensionsPropertiesToRemove: string[],
	extensionParameters: LinkCardsParameters,
): LinkCardsParameters => {
	const mutatedParameters = { ...extensionParameters };

	extensionsPropertiesToRemove.forEach((field) => {
		delete mutatedParameters[field];
	});

	return mutatedParameters;
};

const updateCardInList = (cards: Card[], cardId: string, updatedField: UpdateField<Card>): Card[] =>
	cards.map((card) => {
		if (card.cardId === cardId) {
			return { ...card, ...updatedField };
		}
		return card;
	});

export const useExtensionParametersAndActions = ({
	localId,
	editorActions,
	extensionType,
}: UseExtensionParametersAndActionsProps): UseExtensionParametersAndActionsResponse => {
	const [extensionParameters, setExtensionParameters] = useState<LinkCardsParameters>(
		fetchLocalIdMacroParameters(editorActions, localId, extensionType),
	);
	const [_, { setSelectedToEditCardId }] = useCustomSitesExtensions();
	const { cards } = extensionParameters ?? {};

	useEffect(() => {
		const extensionNodeParams = fetchLocalIdMacroParameters(editorActions, localId, extensionType);
		setExtensionParameters(extensionNodeParams);
	}, [editorActions, localId, extensionType]);

	const updateExtensionParams = useCallback(
		(
			newExtensionParameters: UpdateField<LinkCardsParameters>,
			options: { fieldsToRemove?: string[] } = {},
		) => {
			let mutatedFields = { ...extensionParameters };
			const fieldsToRemove = [...(options?.fieldsToRemove || [])];

			if (fieldsToRemove.length > 0) {
				mutatedFields = removeProperties(fieldsToRemove, extensionParameters);
			}

			const updatedParams = {
				...mutatedFields,
				...newExtensionParameters,
			} as LinkCardsParameters;

			// Save params to ADF and state
			updateNodeParameters(editorActions, updatedParams);
			setExtensionParameters(updatedParams);
		},
		[editorActions, extensionParameters],
	);

	const addCard = useCallback(() => {
		const newCard = createNewCardWithDefaultParameters();
		updateExtensionParams({
			cards: [...(checkCards(cards) || []), newCard],
		});
		setSelectedToEditCardId(newCard.cardId);
	}, [cards, updateExtensionParams, setSelectedToEditCardId]);

	const removeCard = useCallback(
		(cardId: string) =>
			updateExtensionParams({
				cards: cards.filter((card) => card.cardId !== cardId),
			}),
		[cards, updateExtensionParams],
	);

	const updateMainFormField = useCallback(
		(updatedFields: UpdateField<LinkCardsParameters>, options = {}) =>
			updateExtensionParams(updatedFields, options),
		[updateExtensionParams],
	);

	const updateCardFormField = useCallback(
		(cardId: string, updatedField: UpdateField<Card>) => {
			const updatedCards = updateCardInList(cards, cardId, updatedField);

			updateExtensionParams({ cards: updatedCards });
		},
		[cards, updateExtensionParams],
	);

	const extensionActions: ExtensionActions = useMemo(
		() => ({
			addCard,
			removeCard,
			updateMainFormField,
			updateCardFormField,
		}),
		[addCard, removeCard, updateMainFormField, updateCardFormField],
	);

	return { extensionParameters, extensionActions };
};
