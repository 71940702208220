import { TypeFilterValueKey } from '../../filters';
import { FigmaIcon } from '../../icons/figma';
import { FigmaSearchResultIcon } from '../../icons/figma-search';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const figmaConfig: ThirdPartyConfig = {
	displayName: 'Figma',
	id: 'figma',
	resourceType: 'figma-connector',
	icon: FigmaIcon,
	searchResultIcon: FigmaSearchResultIcon,
	integrationAri: 'ari:cloud:platform::integration/figma',
	typeFilterValues: [TypeFilterValueKey.Design],
	filters: ['lastUpdated'],
	requiresOauth: true,
	isEnabled: () => true,
};
