import React from 'react';

import type { CustomGlyphProps } from '@atlaskit/icon/types';

export const TeamsSearchResultIcon = (_: CustomGlyphProps) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_6321_165930)">
			<path
				d="M20.7408 13H26.94C27.5257 13 28.0005 13.5104 28.0005 14.14V20.2102C28.0005 22.5242 26.2555 24.4 24.103 24.4H24.0846C21.932 24.4003 20.1868 22.5248 20.1865 20.2108C20.1865 20.2106 20.1865 20.2104 20.1865 20.2102V13.5958C20.1865 13.2667 20.4347 13 20.7408 13Z"
				fill="#5059C9"
			/>
			<path
				d="M24.9306 11.7999C26.3177 11.7999 27.4422 10.5911 27.4422 9.0999C27.4422 7.60873 26.3177 6.3999 24.9306 6.3999C23.5434 6.3999 22.4189 7.60873 22.4189 9.0999C22.4189 10.5911 23.5434 11.7999 24.9306 11.7999Z"
				fill="#5059C9"
			/>
			<path
				d="M17.1162 11.8C19.1198 11.8 20.7441 10.0539 20.7441 7.9C20.7441 5.74609 19.1198 4 17.1162 4C15.1126 4 13.4883 5.74609 13.4883 7.9C13.4883 10.0539 15.1126 11.8 17.1162 11.8Z"
				fill="#7B83EB"
			/>
			<path
				d="M21.954 13H11.7211C11.1424 13.0154 10.6846 13.5317 10.698 14.1538V21.0772C10.6172 24.8106 13.3648 27.9086 16.8376 28C20.3103 27.9086 23.0579 24.8106 22.9771 21.0772V14.1538C22.9905 13.5317 22.5327 13.0154 21.954 13Z"
				fill="#7B83EB"
			/>
			<path
				opacity="0.1"
				d="M17.3952 13V22.702C17.3925 23.1469 17.1417 23.5466 16.759 23.716C16.6371 23.7714 16.5061 23.8 16.3738 23.8H11.1887C11.1162 23.602 11.0492 23.404 10.9934 23.2C10.798 22.5115 10.6983 21.7958 10.6976 21.076V14.152C10.6841 13.5309 11.1412 13.0154 11.7189 13H17.3952Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.8371 13V23.302C16.8371 23.4442 16.8105 23.585 16.759 23.716C16.6014 24.1274 16.2296 24.397 15.8157 24.4H11.4511C11.3562 24.202 11.2669 24.004 11.1887 23.8C11.1106 23.596 11.0492 23.404 10.9934 23.2C10.798 22.5115 10.6983 21.7958 10.6976 21.076V14.152C10.6841 13.5309 11.1412 13.0154 11.7189 13H16.8371Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.8371 13V22.102C16.8328 22.7065 16.378 23.1954 15.8157 23.2H10.9934C10.798 22.5115 10.6983 21.7958 10.6976 21.076V14.152C10.6841 13.5309 11.1412 13.0154 11.7189 13H16.8371Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.2789 13V22.102C16.2747 22.7065 15.8199 23.1954 15.2576 23.2H10.9934C10.798 22.5115 10.6983 21.7958 10.6976 21.076V14.152C10.6841 13.5309 11.1412 13.0154 11.7189 13H16.2789Z"
				fill="black"
			/>
			<path
				opacity="0.1"
				d="M17.3954 9.89804V11.788C17.3005 11.794 17.2112 11.8 17.1163 11.8C17.0214 11.8 16.9321 11.794 16.8372 11.788C16.6488 11.7746 16.462 11.7425 16.2791 11.692C15.1489 11.4043 14.2151 10.5522 13.7675 9.40005C13.6905 9.20656 13.6307 9.00565 13.5889 8.80005H16.374C16.9372 8.80234 17.3932 9.29258 17.3954 9.89804Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.8373 10.4979V11.7879C16.6489 11.7745 16.4621 11.7423 16.2792 11.6919C15.149 11.4042 14.2152 10.5521 13.7676 9.3999H15.8159C16.3792 9.40219 16.8352 9.89244 16.8373 10.4979Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.8373 10.4979V11.7879C16.6489 11.7745 16.4621 11.7423 16.2792 11.6919C15.149 11.4042 14.2152 10.5521 13.7676 9.3999H15.8159C16.3792 9.40219 16.8352 9.89244 16.8373 10.4979Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M16.2792 10.4979V11.6919C15.149 11.4042 14.2152 10.5521 13.7676 9.3999H15.2578C15.821 9.40221 16.2771 9.89245 16.2792 10.4979Z"
				fill="black"
			/>
			<path
				d="M5.02306 9.3999H15.256C15.821 9.3999 16.2791 9.8923 16.2791 10.4997V21.5001C16.2791 22.1075 15.821 22.5999 15.256 22.5999H5.02306C4.45804 22.5999 4 22.1075 4 21.5001V10.4997C4 9.8923 4.45805 9.3999 5.02306 9.3999Z"
				fill="#5059C9"
			/>
			<path
				d="M12.8322 13.5869H10.7866V19.5749H9.48336V13.5869H7.44727V12.4253H12.8322V13.5869Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_6321_165930">
				<rect width="24" height="24" fill="white" transform="translate(4 4)" />
			</clipPath>
		</defs>
	</svg>
);
