import { OutlookCalendarIcon } from '../../icons/outlook-calendar';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const outlookCalendarConfig: ThirdPartyConfig = {
	displayName: 'Outlook Calendar',
	id: 'outlook-calendar',
	resourceType: 'outlook-connector',
	icon: OutlookCalendarIcon,
	integrationAri: 'ari:cloud:platform::integration/outlook-calendar',
	// TODO: >>>> QS-5465: Uncomment when calendar nouns are ready to be ingested
	typeFilterValues: [],
	// typeFilterValues: [TypeFilterValueKey.Calendar],
	requiresOauth: true,
	isEnabled: () => false,
};
