export enum CoverPictureIdPropertyKey {
	PUBLISHED = 'cover-picture-id-published',
	DRAFT = 'cover-picture-id-draft',
}

export function propertyToId(p) {
	let value;
	let version;
	const DEFAULT_POSITION_VALUE = 50;

	try {
		value = JSON.parse(p.value);
		version = p.version ? parseInt(JSON.parse(p.version.number)) : null;

		// Counting quotes to determine if the value we receive is a string (old cover photos experience without repositioning feature)
		// or object (new cover photos experience with repositioning feature - has a position property in the object).
		// The new designs will have value as an object but stored as a string: "{"id":"http://website.com","position":45}"
		// We need to JSON.parse it again to turn it into an object. The old cover photos will just have value as a string: value: "123-abc",
		// so there is no need to JSON parse it again. We will set its position as the default position value to support this new value format.

		const quoteMatches = value.match(/"/g);
		const quoteCount = quoteMatches ? quoteMatches.length : 0;

		// if quoteCount != null this mean value is part of the new experience so we need to JSON parse the info again since it is a stored as a string and we want the object
		if (quoteCount) {
			try {
				value = JSON.parse(value);
			} catch (e) {
				value = null;
			}
		} else {
			// otherwise it was just a regular string (part of old experience without repositioning)
			value = { id: value, position: DEFAULT_POSITION_VALUE };
		}
	} catch (e) {
		value = null;
		version = null;
	}
	return p
		? {
				value,
				version,
			}
		: null;
}
