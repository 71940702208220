import { TypeFilterValueKey } from '../../filters';
import { OneDriveIcon } from '../../icons/onedrive';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const oneDriveConfig: ThirdPartyConfig = {
	displayName: 'OneDrive',
	id: 'onedrive',
	resourceType: 'onedrive-connector',
	icon: OneDriveIcon,
	integrationAri: 'ari:cloud:platform::integration/onedrive',
	typeFilterValues: [
		TypeFilterValueKey.Document,
		TypeFilterValueKey.Spreadsheet,
		TypeFilterValueKey.Presentation,
	],
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => true,
};
