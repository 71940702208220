import { AnyAri } from '@atlassian/ari';

import type { Maybe, SearchResult } from '../../../../common/types';
import type { ThirdPartyUser } from '../types';

import { ExternalBranchPartial } from './ExternalBranchPartial';
import { ExternalCalendarEventPartial } from './ExternalCalendarEventPartial';
import { ExternalCommitPartial } from './ExternalCommitPartial';
import { ExternalConversationPartial } from './ExternalConversationPartial';
import { ExternalDesignPartial } from './ExternalDesignPartial';
import { ExternalDocumentPartial } from './ExternalDocumentPartial';
import { ExternalMessagePartial } from './ExternalMessagePartial';
import { ExternalPullRequestPartial } from './ExternalPullRequestPartial';
import { ExternalRepositoryPartial } from './ExternalRepositoryPartial';
import { ExternalVideoPartial } from './ExternalVideoPartial';

const TEAMS_INTEGRATION_ARI = 'ari:cloud:platform::integration/microsoft';

const SearchThirdPartyGraphDocumentPartial = `
... on SearchResultGraphDocument {
	excerpt,
	permissionLevel,
	initialContributors {
		name
	}
	subtype
	integrationId
	lastModifiedDate
	linkedEntities {
		id
		title
		excerpt,
		permissionLevel,
		subtype
		integrationId
		lastModifiedDate
		entity {
			${ExternalMessagePartial}
		}
	}
	entity {
		${ExternalBranchPartial}
		${ExternalCalendarEventPartial}
		${ExternalCommitPartial}
		${ExternalConversationPartial}
		${ExternalDesignPartial}
		${ExternalDocumentPartial}
		${ExternalMessagePartial}
		${ExternalPullRequestPartial}
		${ExternalRepositoryPartial}
		${ExternalVideoPartial}
	}
}
`;

export type SearchResultEntity =
	| ExternalBranch
	| ExternalCalendarEvent
	| ExternalCommit
	| ExternalConversation
	| ExternalDesign
	| ExternalDocument
	| ExternalMessage
	| ExternalPullRequest
	| ExternalRepository
	| ExternalVideo
	| ExternalWorkItem;

export type SearchResultBranchPartial = SearchResultGraphDocument<ExternalBranch>;
export type SearchResultCalendarEventPartial = SearchResultGraphDocument<ExternalCalendarEvent>;
export type SearchResultCommitPartial = SearchResultGraphDocument<ExternalCommit>;
export type SearchResultConversationPartial = SearchResultGraphDocument<ExternalConversation>;
export type SearchResultDesignPartial = SearchResultGraphDocument<ExternalDesign>;
export type SearchResultDocumentPartial = SearchResultGraphDocument<ExternalDocument>;
export type SearchResultMessagePartial = SearchResultGraphDocument<ExternalMessage>;
export type SearchResultPullRequestPartial = SearchResultGraphDocument<ExternalPullRequest>;
export type SearchResultRepositoryPartial = SearchResultGraphDocument<ExternalRepository>;
export type SearchResultVideoPartial = SearchResultGraphDocument<ExternalVideo>;
export type SearchResultWorkItemPartial = SearchResultGraphDocument<ExternalWorkItem>;

export type ThirdPartyPRestrictionLevel = 'restricted' | 'public' | 'private';

export interface SearchResultGraphDocument<EntityType extends SearchResultEntity>
	extends SearchResult {
	excerpt?: string;
	permissionLevel: Maybe<ThirdPartyPRestrictionLevel>;
	subtype?: string;
	integrationId?: string;
	lastModifiedDate?: string;
	initialContributors?: ThirdPartyUser[];
	linkedEntities?: SearchResultGraphDocument<EntityType>[];
	entity?: EntityType;
}

enum AccountStatus {
	active,
	inactive,
	closed,
}

export interface ExternalUser {
	thirdPartyUser?: ThirdPartyUser;
	user?: {
		id: string;
		accountId: string;
		canonicalAccountId: string;
		accountStatus: AccountStatus;
		name: string;
		picture: string;
	};
}

interface ExternalThumbnail {
	externalUrl?: string;
}

enum ExternalDocumentCategory {
	ARCHIVE,
	AUDIO,
	CODE,
	DOCUMENT,
	FOLDER,
	FORM,
	IMAGE,
	OTHER,
	PDF,
	PRESENTATION,
	SHORTCUT,
	SPREADSHEET,
	VIDEO,
	WEB_PAGE,
}

interface ExternalDocumentType {
	category?: ExternalDocumentCategory;
	fileExtension?: string;
	iconUrl?: string;
	mimeType?: string;
}

interface ExternalLargeContent {
	asText?: string;
	mimeType?: string;
}

interface ExternalExportLink {
	mimeType?: string;
	url?: string;
}

export interface ExternalDocument {
	byteSize?: number;
	collaborators?: ExternalUser[];
	content?: ExternalLargeContent;
	createdAt?: string;
	createdBy?: ExternalUser;
	displayName?: string;
	exportLinks?: ExternalExportLink[];
	externalId?: string;
	id: string;
	lastUpdated?: string;
	lastUpdatedBy?: ExternalUser;
	owners?: ExternalUser[];
	parentId?: string;
	thumbnail?: ExternalThumbnail;
	truncatedDisplayName?: boolean;
	type?: ExternalDocumentType;
	updateSequenceNumber?: number;
	url?: string;
	parent?: ExternalDocument;
}

export interface ExternalDesign {
	thumbnail?: { externalUrl?: string };
}

export type ExternalVideo = {
	thumbnailUrl?: string;
	durationInSeconds?: number;
	chapters?: ExternalChapter[];
	createdBy?: ExternalUser;
};

export type ExternalChapter = {
	title?: string;
	startTimeInSeconds?: number;
};

export interface ExternalMessage {
	attachments?: ExternalAttachment[];
	container?: ExternalConversation;
	createdAt?: string;
	createdBy?: ExternalUser;
	description?: string;
	displayName?: string;
	externalId?: string;
	hidden?: boolean;
	id: string;
	isPinned?: boolean;
	largeContentDescription?: ExternalLargeContent;
	lastUpdated?: string;
	lastUpdatedBy?: ExternalUser;
	owners?: ExternalUser[];
	parentId?: string;
	thumbnail?: ExternalThumbnail;
	updateSequenceNumber?: number;
	url?: string;
	associatedWith?: {
		edges?: AssociationEdge<AssociationEdgeEntity>[];
	};
}

export type AssociationEdgeEntity = ThirdPartyUser | ExternalConversation;
export interface AssociationEdge<T extends AssociationEdgeEntity> {
	node: { entity: T; title?: string };
}

export interface ExternalAttachment {
	title?: string;
	byteSize?: number;
	mimeType?: string;
	thumbnailUrl?: string;
	url?: string;
}

export interface ExternalBranch {
	name?: string;
	container?: Pick<ExternalRepository, 'name'>;
}

export interface ExternalCommit {
	author: ExternalUser;
	container?: Pick<ExternalRepository, 'name'>;
}

export interface ExternalPullRequest {
	title?: string;
	lastUpdate?: string;
	pullRequestId?: string;
	container?: Pick<ExternalRepository, 'name'>;
}

export interface ExternalRepository {
	avatarDescription?: string;
	avatarUrl?: string;
	description?: string;
	forkOfId?: string;
	id: string;
	repositoryId?: string;
	url?: string;
	name?: string;
}

export interface ExternalConversation {
	__typename?: 'ExternalConversation';
	createdAt?: string;
	createdBy?: ExternalUser;
	channelId?: string;
	description?: string;
	displayName?: string;
	externalId?: string;
	id: string;
	isArchived?: boolean;
	lastUpdated?: string;
	lastUpdatedBy?: ExternalUser;
	memberCount?: number;
	members?: Array<ExternalUser | null>;
	membershipType?: ExternalMembershipType;
	owners?: Array<ExternalUser | null>;
	topic?: string;
	type?: ExternalConversationType;
	updateSequenceNumber?: number;
	url?: string;
	workspace?: string;
	associatedWith?: {
		edges?: AssociationEdge<AssociationEdgeEntity>[];
	};
}

// TODO: [QS-5294] >>>> This could change based on the finalised schema from aggregator
export interface ExternalCalendarEvent {
	eventStartTime: string;
	eventEndTime: string;
	// organizer: string;
	attendees: string[];
	attendeeCount: number;
	location?: string;
	videoMeetingURL?: string;
	recurringEventID?: string;
	// eventType: string; // NOTE: This could be an enum of categories such as `event` and `focus time`
	parentCalendar: {
		id: string;
		name: string;
	};
	// attachmentURL: string[];
}

export interface ExternalWorkItem {
	assignee?: ExternalUser;
	attachments: [ExternalAttachment];
	collaborators?: [ExternalUser];
	createdBy: ExternalUser;
	displayName: string;
	dueDate?: string;
	exceedsMaxCollaborators?: boolean;
	id: string;
	projectName?: string;
	status: string;
	subType?: ExternalWorkItemType;
	team: string;
}

enum ExternalWorkItemType {
	TASK = 'TASK',
	DEFAULT_TASK = 'DEFAULT_TASK',
	WORK_ITEM = 'WORK_ITEM',
	EPIC = 'EPIC',
	BUG = 'BUG',
	STORY = 'STORY',
	MILESTONE = 'MILESTONE',
	SECTION = 'SECTION',
	APPROVAL = 'APPROVAL',
	PROBLEM = 'PROBLEM',
	INCIDENT = 'INCIDENT',
	QUESTION = 'QUESTION',
	OTHER = 'OTHER',
}

enum ExternalMembershipType {
	PRIVATE = 'PRIVATE',
	PUBLIC = 'PUBLIC',
	SHARED = 'SHARED',
}

enum ExternalConversationType {
	CHANNEL = 'CHANNEL',
	DIRECT_MESSAGE = 'DIRECT_MESSAGE',
	GROUP_DIRECT_MESSAGE = 'GROUP_DIRECT_MESSAGE',
}

enum ResourceType {
	BRANCH = 'branch',
	CALENDAR_EVENT = 'calendar-event',
	COMMIT = 'commit',
	CONVERSATION = 'conversation',
	DESIGN = 'design',
	DOCUMENT = 'document',
	MESSAGE = 'message',
	PULL_REQUEST = 'pull-request',
	REPOSITORY = 'repository',
	VIDEO = 'video',
	WORK_ITEM = 'work-item',
}

export const getGraphPartials = () => {
	//TODO: update for GitHub graph entities
	return SearchThirdPartyGraphDocumentPartial;
};

export const isSearchResultGraphEntity = (
	partial: SearchResult,
): partial is SearchResultGraphDocument<SearchResultEntity> =>
	partial.__typename === 'SearchResultGraphDocument';

export const isConversationEntity = (
	partial: SearchResult,
): partial is SearchResultConversationPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.CONVERSATION;
};

export const isDesignEntity = (partial: SearchResult): partial is SearchResultDesignPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.DESIGN;
};

export const isMessageEntity = (partial: SearchResult): partial is SearchResultMessagePartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.MESSAGE;
};

export const isVideoEntity = (partial: SearchResult): partial is SearchResultVideoPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.VIDEO;
};

export const isBranchEntity = (partial: SearchResult): partial is SearchResultBranchPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.BRANCH;
};

export const isCommitEntity = (partial: SearchResult): partial is SearchResultCommitPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.COMMIT;
};

export const isPullRequestEntity = (
	partial: SearchResult,
): partial is SearchResultPullRequestPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.PULL_REQUEST;
};

export const isRepositoryEntity = (
	partial: SearchResult,
): partial is SearchResultRepositoryPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.REPOSITORY;
};

export const isDocumentEntity = (partial: SearchResult): partial is SearchResultDocumentPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.DOCUMENT;
};

export const isCalendarEventEntity = (
	partial: SearchResult,
): partial is SearchResultCalendarEventPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.CALENDAR_EVENT;
};

export const isTeamsMessage = (partial: SearchResult) =>
	isMessageEntity(partial) && partial.integrationId === TEAMS_INTEGRATION_ARI;

export const isWorkItemEntity = (partial: SearchResult): partial is SearchResultWorkItemPartial => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.WORK_ITEM;
};
