import { TypeFilterValueKey } from '../../filters';
import { SharePointIcon } from '../../icons/sharepoint';
import { SharePointSearchResultIcon } from '../../icons/sharepoint-search';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const sharepointConfig: ThirdPartyConfig = {
	displayName: 'Microsoft Sharepoint',
	id: 'sharepoint',
	resourceType: 'ai-3p-connector:sharepoint-domain',
	icon: SharePointIcon,
	searchResultIcon: SharePointSearchResultIcon,
	integrationAri: 'ari:cloud:platform::integration/microsoft',
	typeFilterValues: [
		TypeFilterValueKey.Document,
		TypeFilterValueKey.Spreadsheet,
		TypeFilterValueKey.Presentation,
	],
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => true,
};
