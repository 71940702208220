import { TypeFilterValueKey } from '../../filters';
import { AirtableIcon } from '../../icons/airtable';
import { AirtableFilterIcon } from '../../icons/airtable-filter';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const airtableConfig: ThirdPartyConfig = {
	displayName: 'Airtable',
	id: 'airtable',
	resourceType: 'airtable-connector',
	icon: AirtableFilterIcon,
	searchResultIcon: AirtableIcon,
	integrationAri: 'ari:cloud:platform::integration/airtable',
	typeFilterValues: [TypeFilterValueKey.Document],
	requiresOauth: true,
	isEnabled: () => false,
};
