import { TypeFilterValueKey } from '../../filters';
import { NotionIcon } from '../../icons/notion';
import { NotionFilterIcon } from '../../icons/notion-filter';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const notionConfig: ThirdPartyConfig = {
	displayName: 'Notion',
	id: 'notion',
	resourceType: 'notion-connector',
	icon: NotionFilterIcon,
	searchResultIcon: NotionIcon,
	integrationAri: 'ari:cloud:platform::integration/notion',
	typeFilterValues: [TypeFilterValueKey.Document],
	requiresOauth: true,
	isEnabled: () => false,
};
